import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
// layouts
import MainLayout from "src/layouts/main";
import SimpleLayout from "src/layouts/simple";
import CompactLayout from "src/layouts/compact";
// components
import { SplashScreen } from "src/components/loading-screen";
import { OnboardingGuard } from "src/auth/guard";

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import("src/pages/home"));
const Page500 = lazy(() => import("src/pages/500"));
const Page403 = lazy(() => import("src/pages/403"));
const Page404 = lazy(() => import("src/pages/404"));
const OnboardingPage = lazy(() => import("src/pages/onboarding"));
const AboutPage = lazy(() => import("src/pages/about-us"));
const ContactPage = lazy(() => import("src/pages/contact-us"));
const PaymentPage = lazy(() => import("src/pages/payment"));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { path: "about-us", element: <AboutPage /> },
      { path: "contact-us", element: <ContactPage /> },
    ],
  },
  {
    element: (
      <SimpleLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </SimpleLayout>
    ),
    children: [{ path: "payment", element: <PaymentPage /> }],
  },
  {
    element: (
      <OnboardingGuard>
        <SimpleLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </SimpleLayout>
      </OnboardingGuard>
    ),
    children: [{ path: "onboarding", element: <OnboardingPage /> }],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: "500", element: <Page500 /> },
      { path: "404", element: <Page404 /> },
      { path: "403", element: <Page403 /> },
    ],
  },
];
