import Stack from "@mui/material/Stack";
// import Button from "@mui/material/Button";

// hooks
import { useAuthContext } from "src/auth/hooks";
// import { useRouter } from "src/routes/hooks";
// import { useSnackbar } from "src/components/snackbar";

// locales
// import { useLocales } from "src/locales";
import NavItem from "src/components/nav-section/vertical/nav-item";
import { paths } from "src/routes/paths";
import Iconify from "src/components/iconify";
import { navVerticalConfig } from "src/components/nav-section/config";
import { Avatar, Divider, ListItemText } from "@mui/material";

// ----------------------------------------------------------------------

const bottomNavItem = [
  {
    title: "Profile",
    path: paths.dashboard.user.profile,
    icon: <Iconify icon="mdi:user-circle" />,
    disabled: false,
  },
  {
    title: "Account Settings",
    path: paths.dashboard.user.account,
    icon: <Iconify icon="streamline:cog-solid" />,
    disabled: false,
  },
];

// const logoutNavItem = {
//   title: "Logout",
//   path: "#",
//   icon: <Iconify icon="streamline:button-power-1-solid" />,
//   disabled: false,
// };

// ----------------------------------------------------------------------

export default function NavBottom() {
  const {
    // logout,
    user,
  } = useAuthContext();

  // const router = useRouter();
  // const { enqueueSnackbar } = useSnackbar();

  // const handleLogout = async () => {
  //   try {
  //     await logout();
  //     router.replace("/");
  //   } catch (error) {
  //     console.error(error);
  //     enqueueSnackbar("Unable to logout!", { variant: "error" });
  //   }
  // };

  // const { t } = useLocales();

  const config = navVerticalConfig({
    currentRole: "admin",
  });

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: "center",
      }}
    >
      {bottomNavItem.map((el) => (
        <NavItem item={el} config={config} depth={1} />
      ))}
      {/* <Stack alignItems="center">
        <Button
          variant="contained"
          sx={{
            background: "#fff",
            color: "#043592",
            "&:hover": { background: "#dce8ffd4 !important" },
          }}
          onClick={handleLogout}
        >
          {t("Logout")}
        </Button>
      </Stack> */}
      {/* <Stack onClick={handleLogout}>
        <NavItem item={logoutNavItem} config={config} depth={1} />
      </Stack> */}

      <Divider />
      <Stack
        flexDirection="row"
        alignContent="center"
        gap="16px"
        paddingTop={5}
      >
        <Avatar src={user?.photo} />

        <ListItemText
          primary={`${user?.faciltyName || ""}`}
          secondary={`${user?.contact?.email || ""}`}
          primaryTypographyProps={{
            typography: "body2",
            // color: "#fff",
            textAlign: "left",
          }}
          secondaryTypographyProps={{
            component: "span",
            color: "text.disabled",
            textAlign: "left",
          }}
        />
      </Stack>
    </Stack>
  );
}
