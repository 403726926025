import { m } from "framer-motion";
import { useState, useEffect } from "react";
// @mui
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// hooks
import { useNavigate } from "react-router";
import { useBoolean } from "src/hooks/use-boolean";
import { useResponsive } from "src/hooks/use-responsive";
// components
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar";
import { varHover } from "src/components/animate";
//
import customAxios from "src/utils/customAxios";
import NotificationItem from "./notification-item";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const drawer = useBoolean();

  const smUp = useResponsive("up", "sm");

  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  const fetchNotifications = async () => {
    const { data } = await customAxios(`/notifications`);
    setNotifications(data.items);
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const totalUnRead = notifications.filter(
    (item) => item.isUnRead === true
  ).length;

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  const handleClick = async (id, type) => {
    try {
      await customAxios.patch(`/notifications/${id}`);
    } catch (error) {
      console.log(error);
    }
    switch (type) {
      case "appointment":
        navigate(`/dashboard/appointments`);
        break;
      // case "primary-care":
      //   navigate(`/dashboard/primary-care`);
      //   drawer.onFalse();

      //   break;
      // case "lab-order":
      //   navigate(`/dashboard/my-care/labs`);
      //   drawer.onFalse();

      default:
        break;
    }
    fetchNotifications();
  };

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      {!!totalUnRead && (
        <Tooltip title="Mark all as read">
          <IconButton color="primary" onClick={handleMarkAllAsRead}>
            <Iconify icon="eva:done-all-fill" />
          </IconButton>
        </Tooltip>
      )}

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {notifications
          .sort((a, b) => new Date(b.StringDate) - new Date(a.StringDate))
          .map((notification) => (
            <Stack
              key={notification._id}
              onClick={() => handleClick(notification._id, notification.type)}
            >
              <NotificationItem notification={notification} />
            </Stack>
          ))}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? "primary" : "default"}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={notifications.length} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {/* {renderTabs}
          <IconButton onClick={handleMarkAllAsRead}>
            <Iconify icon="solar:settings-bold-duotone" />
          </IconButton> */}
        </Stack>

        <Divider />

        {renderList}

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth size="large">
            View All
          </Button>
        </Box> */}
      </Drawer>
    </>
  );
}
