import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
// auth
import { AuthGuard } from "src/auth/guard";
// layouts
import DashboardLayout from "src/layouts/dashboard";
// components
import { LoadingScreen } from "src/components/loading-screen";
// import { OverviewDiagnosis, OverviewMedicalNotes } from 'src/sections/overview/records/view';
// import AllVitals from '../../pages/dashboard/records/vitals';

// ----------------------------------------------------------------------

// OVERVIEW
export const IndexPage = lazy(() => import("src/pages/dashboard/app"));

// ORDER
const OrderListPage = lazy(() => import("src/pages/dashboard/order/list"));
const OrderDetailsPage = lazy(() =>
  import("src/pages/dashboard/order-details/details")
);
const CompletedListPage = lazy(() =>
  import("src/pages/dashboard/order-completed/list")
);
// const NewListPage = lazy(() => import("src/pages/dashboard/order-new/list"));
const PendingListPage = lazy(() =>
  import("src/pages/dashboard/order-pending/list")
);
const ProcessingListPage = lazy(() =>
  import("src/pages/dashboard/order-processing/list")
);
// APPOINTMENTS
const AppointmentListPage = lazy(() =>
  import("src/pages/dashboard/appointment/list")
);
const AppointmentDetailsPage = lazy(() =>
  import("src/pages/dashboard/appointment/details")
);
const AppointmentCreatePage = lazy(() =>
  import("src/pages/dashboard/appointment/new")
);
const AppointmentEditPage = lazy(() =>
  import("src/pages/dashboard/appointment/edit")
);

// USERS
const UserProfilePage = lazy(() => import("src/pages/dashboard/user/profile"));
const UserAccountPage = lazy(() => import("src/pages/dashboard/user/account"));

// TRANSACTION
const TransactionPage = lazy(() =>
  import("src/pages/dashboard/transaction/list")
);

// const OverviewHealthHistoryPage = lazy(() => import('src/pages/dashboard/records/health-records'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },

      { path: "transactions", element: <TransactionPage /> },

      {
        path: "user",
        children: [
          { element: <UserProfilePage />, index: true },
          { path: "profile", element: <UserProfilePage /> },

          { path: "account", element: <UserAccountPage /> },
        ],
      },

      {
        path: "order",
        children: [
          { element: <OrderListPage />, index: true },
          { path: "list", element: <OrderListPage /> },
          { path: "processing", element: <ProcessingListPage /> },
          // { path: "new", element: <NewListPage /> },
          { path: "pending", element: <PendingListPage /> },
          { path: "completed", element: <CompletedListPage /> },
          { path: ":id", element: <OrderDetailsPage /> },
        ],
      },
      {
        path: "appointments",
        children: [
          { element: <AppointmentListPage />, index: true },
          { path: "list", element: <AppointmentListPage /> },
          { path: ":id", element: <AppointmentDetailsPage /> },
          { path: ":id/edit", element: <AppointmentEditPage /> },
          { path: "new", element: <AppointmentCreatePage /> },
        ],
      },

      // {
      //   path: 'job',
      //   children: [
      //     { element: <JobListPage />, index: true },
      //     { path: 'list', element: <JobListPage /> },
      //     { path: ':id', element: <JobDetailsPage /> },
      //     { path: 'new', element: <JobCreatePage /> },
      //     { path: ':id/edit', element: <JobEditPage /> },
      //   ],
      // },
    ],
  },
];
