import Stack from "@mui/material/Stack";
// import Button from "@mui/material/Button";

// hooks
import { useAuthContext } from "src/auth/hooks";
import { useRouter } from "src/routes/hooks";
import { useSnackbar } from "src/components/snackbar";

// locales
// import { useLocales } from "src/locales";
import NavItem from "src/components/nav-section/mini/nav-item";
import { paths } from "src/routes/paths";
import Iconify from "src/components/iconify";
import { navVerticalConfig } from "src/components/nav-section/config";
import { Divider } from "@mui/material";

// ----------------------------------------------------------------------

const bottomNavItem = [
  {
    title: "Profile",
    path: paths.dashboard.user.profile,
    icon: <Iconify icon="mdi:user-circle" />,
    disabled: false,
  },
  {
    title: "Account Settings",
    path: paths.dashboard.user.account,
    icon: <Iconify icon="streamline:cog-solid" />,
    disabled: false,
  },
];

const logoutNavItem = {
  title: "Logout",
  path: "#",
  icon: <Iconify icon="streamline:button-power-1-solid" />,
  disabled: false,
};

// ----------------------------------------------------------------------

export default function NavBottomMini() {
  const { logout } = useAuthContext();
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    try {
      await logout();
      router.replace("/");
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  // const { t } = useLocales();

  const config = navVerticalConfig({
    currentRole: "admin",
  });

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: "center",
      }}
    >
      {bottomNavItem.map((el) => (
        <NavItem item={el} config={config} depth={1} />
      ))}
      {/* <Stack alignItems="center">
        <Button
          variant="contained"
          sx={{
            background: "#fff",
            color: "#043592",
            "&:hover": { background: "#dce8ffd4 !important" },
          }}
          onClick={handleLogout}
        >
          {t("Logout")}
        </Button>
      </Stack> */}

      <Divider />
      <Stack onClick={handleLogout} paddingTop={3}>
        <NavItem item={logoutNavItem} config={config} depth={1} />
      </Stack>
    </Stack>
  );
}
